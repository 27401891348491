import clsx from "clsx"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import AppScreenshot from "../../../components/AppScreenshot"
import BackgroundGradients from "../../../components/BackgroundGradients"
import Layout from "../../../components/Layout"
import PageHeader from "../../../components/PageHeader"
import { NewsletterSignup } from "../../../components/PromoBlade"
import Seo from "../../../components/Seo"
import * as styles from "./FeaturesPage.module.scss"

const FeaturesPage = () => (
  <Layout>
    <Seo
      title="Helping you nail your fears!"
      description="The ultimate mobile app to help you overcome your fears, phobias and anxieties."
    />

    <PageHeader
      title="Nail your fears!"
      subtitle="The ultimate mobile app to help you overcome your fears, phobias and anxieties."
      fullHeight
      color="light"
      classes={{
        title: styles.pageHeaderTitle,
        subtitle: styles.pageHeaderSubtitle,
      }}
    />

    <div className={styles.pageContent}>
      <Container fluid="xxl">
        <div className={styles.block1}>
          <Row className="align-items-end">
            <Col xl="7">
              <h2 className={styles.largeHeading}>
                It’s time to stop avoiding your fears.
              </h2>
            </Col>
            <Col md={{ span: 5, offset: 7 }} xl={{ span: 5, offset: 0 }}>
              <p className="mb-md-0">
                When you have a debilitating fear, you might tell yourself,
                “I’ll be alright as long as I avoid it.” But, life is
                unexpected, and you can’t avoid things forever.
              </p>
            </Col>
          </Row>
        </div>

        <div className={styles.block2}>
          <Row className="gx-5 gy-6 gy-md-0">
            <Col md="4">
              <AppScreenshot
                name="phobia-onboarding-intro"
                alt="Screenshot of GritLife app"
                copy="But what if you could wake up tomorrow and not have that anxiety or worry that you might be triggered today?"
                classes={{ image: styles.screenshot1Image }}
              />
            </Col>
            <Col md="4">
              <AppScreenshot
                name="onboarding-screen-1"
                alt="Screenshot of GritLife app"
                copy="GritLife is a safe and scientifically proven digital phobia management solution. We distill years of expert knowledge into simple micro-mentoring journeys that will help you conquer your fear."
                justifyCopy="start"
                alignCopy="center"
                classes={{ image: styles.screenshot2Image }}
                className={styles.screenshot2}
              />
            </Col>
            <Col md="4">
              <AppScreenshot
                name="programs-preview"
                alt="Screenshot of GritLife app"
                copy="Well, you can, and all it takes is a few minutes every day and your phone."
                justifyCopy="start"
                classes={{ image: styles.screenshot3Image }}
                className={styles.screenshot3}
              />
            </Col>
          </Row>
        </div>

        <Row className="align-items-center mb-5">
          <Col md={{ span: 10 }} xl={{ span: 9, offset: 3 }}>
            <h2 className="h1 mb-md-6">Nail your fears!</h2>
          </Col>
          <Col md="5">
            <p className="mb-0">
              We’re here to cure your fears. Whether it’s a fear of dogs,
              vomiting or even storms, GritLife uses accessible learning
              techniques to help train you to overcome your anxieties and
              rebuild your brain so you can get the most out of life!
            </p>
          </Col>
        </Row>

        <div className={styles.block3}>
          <Row className="gy-6 gy-md-0">
            <Col md="4">
              <AppScreenshot
                name="phobia-onboarding-intro-child select"
                alt="Screenshot of GritLife app"
                copy="Start, pause or go. Grow at your own pace."
                copyPosition="above"
                justifyCopy="start"
                alignCopy="center"
                className={styles.screenshot1}
              />
            </Col>
            <Col md="4">
              <AppScreenshot
                name="program-phobia-session-end"
                alt="Screenshot of GritLife app"
                copy="Foster a growth mindset to overcome your worries."
                copyPosition="above"
                justifyCopy="start"
                alignCopy="center"
                className={styles.screenshot2}
              />
            </Col>
            <Col md="4">
              <AppScreenshot
                name="program-phobia-session-fail"
                alt="Screenshot of GritLife app"
                copy="Turn bad habits into good and never feel scared of your triggers."
                copyPosition="above"
                justifyCopy="start"
                alignCopy="center"
                className={styles.screenshot3}
              />
            </Col>
          </Row>
        </div>

        <div className={styles.block4}>
          <h2
            className={clsx(
              "text-md-center",
              styles.largeHeading,
              styles.heading
            )}
          >
            Nail your fears!
          </h2>
        </div>
      </Container>
    </div>

    <NewsletterSignup />

    <BackgroundGradients
      intensity="high"
      className={styles.backgroundGradients}
    >
      <div className={styles.gradientBackground}></div>

      <div className={styles.red1}></div>
      <div className={styles.blue1}></div>
      <div className={styles.pink1}></div>
      <div className={styles.yellow1}></div>
      <div className={styles.black1}></div>
      <div className={styles.orange1}></div>
      <div className={styles.orange2}></div>
      <div className={styles.pink2}></div>
    </BackgroundGradients>
  </Layout>
)

export default FeaturesPage
