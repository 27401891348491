// extracted by mini-css-extract-plugin
export var pageHeaderTitle = "FeaturesPage-module--page-header-title--BjHMg";
export var pageHeaderSubtitle = "FeaturesPage-module--page-header-subtitle--K2j3H";
export var pageContent = "FeaturesPage-module--page-content--5nK8g";
export var largeHeading = "FeaturesPage-module--large-heading--HIxpP";
export var block1 = "FeaturesPage-module--block-1--wr2Qy";
export var block2 = "FeaturesPage-module--block-2--b3Ai5";
export var screenshot1Image = "FeaturesPage-module--screenshot-1-image--CSezz";
export var screenshot2 = "FeaturesPage-module--screenshot-2--5Oiai";
export var screenshot3 = "FeaturesPage-module--screenshot-3--kuAUG";
export var block3 = "FeaturesPage-module--block-3--zSPjj";
export var screenshot1 = "FeaturesPage-module--screenshot-1--fi3xZ";
export var block4 = "FeaturesPage-module--block-4--Oj-YG";
export var heading = "FeaturesPage-module--heading--8qWxD";
export var backgroundGradients = "FeaturesPage-module--background-gradients--T5BLm";
export var red1 = "FeaturesPage-module--red-1--KOHGE";
export var blue1 = "FeaturesPage-module--blue-1--nJR97";
export var yellow1 = "FeaturesPage-module--yellow-1--R7Sql";
export var pink1 = "FeaturesPage-module--pink-1--jQdX9";
export var orange1 = "FeaturesPage-module--orange-1--FXAEO";
export var black1 = "FeaturesPage-module--black-1--WQeLo";
export var pink2 = "FeaturesPage-module--pink-2--VtM1p";
export var orange2 = "FeaturesPage-module--orange-2--EYXpK";
export var gradientBackground = "FeaturesPage-module--gradient-background--xbYtC";