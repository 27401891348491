import clsx from "clsx"
import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import { Container } from "react-bootstrap"
import * as styles from "./PageHeader.module.scss"

export interface PageHeaderProps {
  /**
   * Primary title.
   */
  title: string

  /**
   * Optional text appearing underneath the primary title.
   */
  subtitle?: string

  /**
   * Optional text appearing above the primary title.
   */
  overline?: string

  /**
   * Color the heading copy to improve contrast with the background.
   * Dark looks best on top of light backgrounds, light on top of dark backgrounds
   * (i.e. background images).
   * @default 'dark'
   */
  color?: "dark" | "light"

  /**
   * Extend the header height to fill the viewport.
   *
   * @default false
   */
  fullHeight?: boolean

  /**
   * Display an image behind the content. Generally works well with 'dark' color.
   * TODO: Support different images.
   */
  backgroundImage?: string

  /**
   * Animate the header image and titles on scroll. Requires the page component
   * to be wrapped in locomotive-scroll.
   *
   * @default false
   */
  animated?: boolean

  /**
   * Style specific parts of the component with classNames.
   */
  classes?: Record<string, string>

  className?: string
}

/**
 * Page title heading with consistent center alignment and padding. May take up
 * 100% of the viewport height.
 * Can also include a subtitle, overline.
 */
const PageHeader = ({
  title,
  subtitle,
  overline,
  color = "dark",
  fullHeight = false,
  backgroundImage,
  animated = false,
  classes = {},
  className,
  ...rest
}: PageHeaderProps) => {
  const renderedTitle = (
    <h1
      {...(animated
        ? {
            "data-scroll": true,
            "data-scroll-speed": 2,
            "data-scroll-delay": 0.1,
            "data-scroll-position": "top",
          }
        : {})}
      className={clsx("display-1", styles.title, classes.title)}
    >
      {title}
    </h1>
  )

  const renderedSubtitle = subtitle ? (
    <h2
      {...(animated
        ? {
            "data-scroll": true,
            "data-scroll-speed": 1,
            "data-scroll-delay": 0.1,
            "data-scroll-position": "top",
          }
        : {})}
      className={clsx(styles.subtitle, classes.subtitle)}
    >
      {subtitle}
    </h2>
  ) : null

  return (
    <header
      className={clsx(
        styles.pageHeader,
        fullHeight && styles.fullHeight,
        color === "light" && styles.lightContent,
        className
      )}
      {...rest}
    >
      <Container fluid="xxl">
        <div className={clsx(styles.titleWrapper, classes.titleWrapper)}>
          {overline ? (
            <h5
              className={clsx(
                "display-4 text-success mb0",
                styles.overline,
                classes.overline
              )}
            >
              {overline}
            </h5>
          ) : null}
          {renderedTitle}
          {renderedSubtitle}
        </div>
      </Container>

      {backgroundImage ? (
        <div className={styles.backgroundImageWrapper}>
          <StaticImage
            src="../../images/temp-page-header-bg.jpg"
            alt=""
            layout="fullWidth"
            objectFit="cover"
            loading="eager"
            className={styles.backgroundImage}
          />
        </div>
      ) : null}
    </header>
  )
}

export default PageHeader
